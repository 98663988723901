<template>
  <form @submit.prevent="handleSubmit()" class="w-full">
    <div
      v-for="(formElement, index) in formElements"
      :key="formElement.id"
      class="grid grid-cols-12"
    >
      <div :class="formElement.class">
        <hr
          class="my-2 w-full border-t border-gray-300"
          :class="
            formStyle == 'block' || index == 0 ? 'hidden' : 'hidden sm:block'
          "
        />
        <form-button
          v-if="formElement.element == 'button'"
          class="mt-2"
          :class="formStyle == 'block' ? 'w-full' : null"
          :command="formElement.command"
          :disabled="formElement.disabled"
          :id="formElement.id"
          :label="formElement.label"
          :textVariant="formElement.textVariant"
          :type="formElement.type"
          :variant="formElement.variant"
        />
        <div
          v-else
          class="my-3"
          :class="formStyle == 'block' ? null : 'justify-between sm:flex'"
        >
          <label
            class="mb-1 block"
            :class="formStyle == 'block' ? 'w-full' : 'w-full sm:w-1/2'"
            :for="formElement.id"
            >{{ t(`labels.${formElement.label}`) }}</label
          >
          <div :class="formStyle == 'block' ? 'w-full' : 'w-full sm:w-1/2'">
            <form-input
              v-if="formElement.element == 'input'"
              class="w-full"
              :id="formElement.id"
              :invalid="state[formElement.variable].error ? true : false"
              :placeholder="formElement.placeholder"
              :type="formElement.type"
              v-model="state[formElement.variable].value"
            />
            <form-textarea
              v-else-if="formElement.element == 'textarea'"
              class="w-full"
              :id="formElement.id"
              :invalid="state[formElement.variable].error ? true : false"
              :placeholder="formElement.placeholder"
              :rows="formElement.rows"
              v-model="state[formElement.variable].value"
            />
            <form-checkbox
              v-else-if="formElement.element == 'checkbox'"
              :falseValue="formElement.falseValue"
              :id="formElement.id"
              :invalid="state[formElement.variable].error ? true : false"
              :label="formElement.label"
              :trueValue="formElement.trueValue"
              :variant="formElement.variant"
              v-model="state[formElement.variable].value"
            />
            <form-select
              v-else-if="formElement.element == 'select'"
              class="w-full"
              :allOption="formElement.allOption"
              :id="formElement.id"
              :invalid="state[formElement.variable].error ? true : false"
              :noneOption="formElement.noneOption"
              :optionLabel="formElement.optionLabel"
              :options="formElement.options"
              :translatableLabel="formElement.translatableLabel"
              v-model="state[formElement.variable].value"
            />
            <form-multiselect
              v-else-if="formElement.element == 'multiselect'"
              class="w-full"
              :id="formElement.id"
              :invalid="state[formElement.variable].error ? true : false"
              :optionLabel="formElement.optionLabel"
              :options="formElement.options"
              :translatableLabel="formElement.translatableLabel"
              v-model="state[formElement.variable].value"
            />
            <form-colorpicker
              v-else-if="formElement.element == 'colorpicker'"
              class="w-full"
              :id="formElement.id"
              :invalid="state[formElement.variable].error ? true : false"
              v-model="state[formElement.variable].value"
            />
            <form-datepicker
              v-else-if="formElement.element == 'datepicker'"
              class="w-full"
              :id="formElement.id"
              :invalid="state[formElement.variable].error ? true : false"
              :textVariant="formElement.textVariant"
              :variant="formElement.variant"
              v-model="state[formElement.variable].value"
            />
            <form-filepicker
              v-else-if="formElement.element == 'filepicker'"
              class="w-full"
              :accept="formElement.accept"
              :disabled="formElement.disabled"
              :id="formElement.id"
              :invalid="state[formElement.variable].error ? true : false"
              :multiple="formElement.multiple"
              :showFilename="formElement.showFilename"
              :textVariant="formElement.textVariant"
              :variant="formElement.variant"
              v-model="state[formElement.variable].value"
            />
            <form-signature
              v-else-if="formElement.element == 'signature'"
              class="w-full"
              :id="formElement.id"
              :invalid="state[formElement.variable].error ? true : false"
              :textVariant="formElement.textVariant"
              :variant="formElement.variant"
              v-model="state[formElement.variable].value"
            />
            <span
              v-if="state[formElement.variable].error"
              class="text-sm font-semibold text-rose-600"
              >{{ t(`form.errors.${state[formElement.variable].error}`) }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import validate from "../../utils/validators";
import FormButton from "./FormButton.vue";
import FormCheckbox from "./FormCheckbox.vue";
import FormColorpicker from "./FormColorpicker.vue";
import FormDatepicker from "./FormDatepicker.vue";
import FormFilepicker from "./FormFilepicker.vue";
import FormInput from "./FormInput.vue";
import FormMultiselect from "./FormMultiselect.vue";
import FormSelect from "./FormSelect.vue";
import FormSignature from "./FormSignature.vue";
import FormTextarea from "./FormTextarea.vue";
export default {
  components: {
    FormButton,
    FormCheckbox,
    FormColorpicker,
    FormDatepicker,
    FormFilepicker,
    FormInput,
    FormMultiselect,
    FormSelect,
    FormSignature,
    FormTextarea,
  },
  props: {
    formElements: {
      required: true,
      type: Array[Object],
    },
    formStyle: {
      required: true,
      validator(value) {
        return ["block", "inline"].includes(value);
      },
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const state = reactive({});

    for (const formElement of props.formElements) {
      if (formElement.variable) {
        state[formElement.variable] = {
          error: null,
          match: formElement.match,
          rules: formElement.rules,
          value: formElement.value,
        };
      }
    }

    const isFormValid = async (isValid = true) => {
      for (const [_, value] of Object.entries(state)) {
        const match = state[value.match] ? state[value.match].value : null;
        value.error = await validate(value.rules, value.value, match);
        if (value.error) isValid = false;
      }
      return isValid;
    };

    const handleSubmit = async () => {
      const isValid = await isFormValid();
      if (!isValid) return;
      const data = {};
      for (const [key, value] of Object.entries(state)) {
        data[key] = value.value;
      }
      emit("submitForm", data);
    };

    watch(
      () => state,
      () => {
        emit("stateChange", state);
      },
      { deep: true }
    );

    return {
      handleSubmit,
      state,
      t,
    };
  },
};
</script>
