<template>
  <div>
    <canvas
      :id="`${id}-canvas`"
      class="focus:outline-none block h-28 w-full rounded-md border shadow-sm"
      :class="
        invalid
          ? 'border-2 border-rose-600 focus:border-rose-500 focus:ring focus:ring-rose-200'
          : 'border border-slate-300 focus:ring'
      "
      height="112"
      :width="canvasWidth"
    />
    <div class="flex items-center justify-between pt-2">
      <button class="underline" type="button" @click="clear()">
        {{ t("buttons.clear") }}
      </button>
      <form-button
        :command="() => save()"
        :disabled="false"
        id="signature-save-button"
        label="save"
        :textVariant="textVariant"
        type="button"
        :variant="variant"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import FormButton from "./FormButton.vue";
export default {
  components: { FormButton },
  props: {
    id: {
      required: true,
      type: String,
    },
    invalid: {
      required: true,
      type: Boolean,
    },
    modelValue: {
      required: false,
    },
    textVariant: {
      default: "light",
      type: String,
    },
    variant: {
      default: "indigo",
      type: [Object, String],
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const canvas = ref(null);
    const ctx = ref(null);
    const writingMode = ref(false);

    const canvasWidth = ref(0);

    const getCanvasWidth = () => {
      canvasWidth.value = parseInt(canvas.value?.getBoundingClientRect().width);
    };

    const handlePointerDown = (event) => {
      writingMode.value = true;
      ctx.value.beginPath();
      const [positionX, positionY] = getCursorPosition(event);
      ctx.value.moveTo(positionX, positionY);
    };

    const handlePointerUp = () => {
      writingMode.value = false;
    };

    const handlePointerMove = (event) => {
      if (!writingMode.value) return;
      const [positionX, positionY] = getCursorPosition(event);
      ctx.value.lineTo(positionX, positionY);
      ctx.value.stroke();
    };

    const getCursorPosition = (event) => {
      const positionX = event.clientX - event.target.getBoundingClientRect().x;
      const positionY = event.clientY - event.target.getBoundingClientRect().y;
      return [positionX, positionY];
    };

    const clear = () => {
      ctx.value.clearRect(0, 0, canvas.value.width, canvas.value.height);
      emit("update:modelValue", null);
    };

    const save = () => {
      const image = canvas.value?.toDataURL();
      emit("update:modelValue", image);
    };

    onMounted(() => {
      canvas.value = document.querySelector(`#${props.id}-canvas`);

      if (canvas.value) {
        ctx.value = canvas.value.getContext("2d");
        ctx.value.lineWidth = 3;
        ctx.value.lineJoin = ctx.lineCap = "round";

        canvas.value.addEventListener("pointerdown", handlePointerDown, {
          passive: true,
        });
        canvas.value.addEventListener("pointerup", handlePointerUp, {
          passive: true,
        });
        canvas.value.addEventListener("pointermove", handlePointerMove, {
          passive: true,
        });
      }
      window.addEventListener("resize", getCanvasWidth);
      getCanvasWidth();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getCanvasWidth);
    });

    return {
      canvasWidth,
      clear,
      id: props.id,
      save,
      t,
      props,
      textVariant: props.textVariant,
      variant: props.variant,
    };
  },
};
</script>
