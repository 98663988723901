<template>
  <div :id="id" class="relative flex">
    <input
      class="focus:outline-none block cursor-pointer rounded-md border py-1 px-2 text-slate-500 shadow-sm"
      :class="
        invalid
          ? 'border-2 border-rose-600 focus:border-rose-500 focus:ring focus:ring-rose-200'
          : 'border border-slate-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200'
      "
      :id="id"
      readonly
      type="color"
      :value="modelValue"
      @keydown.enter.space.prevent="togglePicker()"
      @click.prevent="
        (event) => {
          event.target.focus();
          togglePicker();
        }
      "
      @touchstart.prevent="
        (event) => {
          event.target.focus();
          togglePicker();
        }
      "
    />
    <div
      v-show="showPicker"
      class="absolute top-full left-0 z-20 rounded-md border border-slate-300 bg-white"
    >
      <div class="flex items-center justify-end border-b border-slate-300 p-3">
        <button
          class="rounded-md p-1 text-sm hover:bg-slate-300"
          type="button"
          @click="togglePicker()"
        >
          <XMarkIcon class="h-5 w-5" />
        </button>
      </div>
      <div class="p-3">
        <div class="flex gap-3">
          <div class="relative">
            <canvas
              :id="`${id}-color-canvas`"
              ref="colorCanvas"
              height="264"
              width="264"
            />
            <svg
              class="absolute -translate-x-1/2 -translate-y-1/2 transform cursor-pointer"
              :id="`${id}-color-marker`"
              ref="colorMarker"
              height="15"
              width="15"
            >
              <circle
                cx="7"
                cy="7"
                r="6"
                stroke="#ccc"
                stroke-width="2"
                fill="none"
              />
            </svg>
          </div>
          <div class="relative">
            <canvas
              :id="`${id}-hue-canvas`"
              ref="hueCanvas"
              height="264"
              width="25"
            />
            <svg
              class="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer"
              :id="`${id}-hue-marker`"
              ref="hueMarker"
              height="11"
              width="29"
            >
              <rect
                height="11"
                width="29"
                stroke="#ccc"
                stroke-width="5"
                fill="none"
              />
            </svg>
          </div>
        </div>
        <div class="mt-3 flex gap-2">
          <div
            class="min-h-full w-10 rounded-md border border-slate-300"
            :style="{ backgroundColor: modelValue }"
          />
          <input
            class="focus:outline-none w-full rounded-md border py-1 px-2 text-slate-500 shadow-sm"
            :class="
              invalid
                ? 'border-2 border-rose-600 focus:border-rose-500 focus:ring focus:ring-rose-200'
                : 'border border-slate-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200'
            "
            :value="modelValue"
            @input="(event) => checkColor(event.target.value)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { onMounted, ref } from "vue";
import { hexToHue, pixelRGB } from "../../utils/colorUtils";
import validate from "../../utils/validators";
export default {
  components: { XMarkIcon },
  props: {
    id: {
      required: false,
      type: String,
    },
    invalid: {
      default: false,
      required: false,
      type: Boolean,
    },
    modelValue: {
      default: "#ff0000",
      type: String,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const colorCanvas = ref(null);
    const colorMarker = ref(null);
    const hueCanvas = ref(null);
    const hueMarker = ref(null);

    const color = ref("#ff0000");
    const showPicker = ref(false);

    const togglePicker = () => {
      showPicker.value = !showPicker.value;
    };

    const clamp = (min, max, value) => {
      return Math.min(Math.max(value, min), max);
    };

    const initializeHueCanvas = () => {
      const canvas = hueCanvas.value;
      const ctx = canvas.getContext("2d");

      const verticalGradient = ctx.createLinearGradient(
        0,
        0,
        0,
        ctx.canvas.height - 1
      );

      verticalGradient.addColorStop(0, `hsl(0,100%,50%)`);
      for (let i = 0; i <= 359; i++) {
        verticalGradient.addColorStop((i + 1) / 361, `hsl(${i},100%,50%)`);
      }
      verticalGradient.addColorStop(1, `hsl(359,100%,50%)`);
      ctx.fillStyle = verticalGradient;
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      const marker = hueMarker.value;

      canvas.addEventListener("click", (event) => {
        const x = event.offsetX;
        const y = event.offsetY;

        marker.style.top = (y / canvas.height) * 100 + "%";

        const pixel = ctx.getImageData(x, y, 1, 1)["data"];

        const rgb = pixelRGB(pixel);
        color.value = rgb;
        updateColorCanvas();
      });

      let isDown = false;

      marker.addEventListener("mousedown", () => {
        isDown = true;
      });

      marker.addEventListener("touchstart", () => {
        isDown = true;
      });

      document.addEventListener("mouseup", () => {
        isDown = false;
      });

      document.addEventListener("touchend", () => {
        isDown = false;
      });

      document.addEventListener("mousemove", (event) => {
        if (isDown && event.target.id == canvas.id) {
          const y = event.offsetY;
          const yPercentage = clamp(0, 100, (y / canvas.height) * 100);

          marker.style.top = `${yPercentage}%`;

          const pixel = ctx.getImageData(0, y, 1, 1)["data"];

          const rgb = pixelRGB(pixel);
          color.value = rgb;
          updateColorCanvas();
        }
      });

      document.addEventListener("touchmove", (event) => {
        if (isDown && event.target.id == canvas.id) {
          const y = event.offsetY;
          const yPercentage = clamp(0, 100, (y / canvas.height) * 100);

          marker.style.top = `${yPercentage}%`;

          const pixel = ctx.getImageData(0, y, 1, 1)["data"];

          const rgb = pixelRGB(pixel);
          color.value = rgb;
          updateColorCanvas();
        }
      });
    };

    const initializeColorCanvas = () => {
      const canvas = colorCanvas.value;
      const ctx = canvas.getContext("2d");

      const horizontalGradient = ctx.createLinearGradient(
        0,
        0,
        ctx.canvas.width,
        0
      );
      horizontalGradient.addColorStop(0, "#fff");
      horizontalGradient.addColorStop(4 / 264, "#fff");
      horizontalGradient.addColorStop(260 / 264, color.value);
      horizontalGradient.addColorStop(1, color.value);
      ctx.fillStyle = horizontalGradient;
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      const verticalGradient = ctx.createLinearGradient(
        0,
        0,
        0,
        ctx.canvas.height
      );
      verticalGradient.addColorStop(0, "rgba(0,0,0,0)");
      verticalGradient.addColorStop(4 / 264, "rgba(0,0,0,0)");
      verticalGradient.addColorStop(260 / 264, "#000");
      verticalGradient.addColorStop(1, "#000");
      ctx.fillStyle = verticalGradient;
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      const marker = colorMarker.value;
      marker.style.top = 0;
      marker.style.left = "100%";

      canvas.addEventListener("click", (event) => {
        const x = event.offsetX;
        const y = event.offsetY;

        marker.style.left = (x / canvas.width) * 100 + "%";
        marker.style.top = (y / canvas.height) * 100 + "%";

        const pixel = ctx.getImageData(x, y, 1, 1)["data"];

        const rgb = pixelRGB(pixel);
        emit("update:modelValue", rgb);
      });

      let isDown = false;

      marker.addEventListener("mousedown", () => {
        isDown = true;
      });

      marker.addEventListener("touchstart", () => {
        isDown = true;
      });

      document.addEventListener("mouseup", () => {
        isDown = false;
      });

      document.addEventListener("touchend", () => {
        isDown = false;
      });

      document.addEventListener("mousemove", (event) => {
        if (isDown && event.target.id == canvas.id) {
          const x = event.offsetX;
          const y = event.offsetY;

          const xPercentage = clamp(0, 100, (x / canvas.width) * 100);
          const yPercentage = clamp(0, 100, (y / canvas.height) * 100);

          marker.style.left = `${xPercentage}%`;
          marker.style.top = `${yPercentage}%`;

          const pixel = ctx.getImageData(x, y, 1, 1)["data"];

          const rgb = pixelRGB(pixel);
          emit("update:modelValue", rgb);
        }
      });

      document.addEventListener("touchmove", (event) => {
        if (isDown && event.target.id == canvas.id) {
          const x = event.offsetX;
          const y = event.offsetY;

          const xPercentage = clamp(0, 100, (x / canvas.width) * 100);
          const yPercentage = clamp(0, 100, (y / canvas.height) * 100);

          marker.style.left = `${xPercentage}%`;
          marker.style.top = `${yPercentage}%`;

          const pixel = ctx.getImageData(x, y, 1, 1)["data"];

          const rgb = pixelRGB(pixel);
          emit("update:modelValue", rgb);
        }
      });
    };

    const updateHueCanvas = (value) => {
      const canvas = hueCanvas.value;
      const ctx = canvas.getContext("2d");

      const marker = hueMarker.value;

      const h = hexToHue(value);
      const yPercentage = (h * 100) / 360;

      marker.style.top = `${yPercentage}%`;

      const pixel = ctx.getImageData(0, (h / 360) * ctx.canvas.height, 1, 1)[
        "data"
      ];

      switch (value) {
        case "#ff0000":
        case "#ffff00":
        case "#00ff00":
        case "#00ffff":
        case "#0000ff":
        case "#ff00ff":
          color.value = value;
          break;
        default:
          const rgb = pixelRGB(pixel);
          color.value = rgb;
          break;
      }

      updateColorCanvas(value);
    };

    const updateColorCanvas = (value = null) => {
      const canvas = colorCanvas.value;
      const ctx = canvas.getContext("2d");

      const horizontalGradient = ctx.createLinearGradient(
        0,
        0,
        ctx.canvas.width,
        0
      );
      horizontalGradient.addColorStop(0, "#fff");
      horizontalGradient.addColorStop(4 / 264, "#fff");
      horizontalGradient.addColorStop(260 / 264, color.value);
      horizontalGradient.addColorStop(1, color.value);
      ctx.fillStyle = horizontalGradient;
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      const verticalGradient = ctx.createLinearGradient(
        0,
        0,
        0,
        ctx.canvas.height
      );
      verticalGradient.addColorStop(0, "rgba(0,0,0,0)");
      verticalGradient.addColorStop(4 / 264, "rgba(0,0,0,0)");
      verticalGradient.addColorStop(260 / 264, "#000");
      verticalGradient.addColorStop(1, "#000");
      ctx.fillStyle = verticalGradient;
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      const marker = colorMarker.value;

      let x = (parseFloat(marker.style.left) / 100) * canvas.width;
      let y = (parseFloat(marker.style.top) / 100) * canvas.height;

      let pixel = null;
      let rgb = null;

      for (let i = 0; i < ctx.canvas.width; i++) {
        for (let j = 0; j < ctx.canvas.height; j++) {
          pixel = ctx.getImageData(i, j, 1, 1)["data"];
          rgb = pixelRGB(pixel);
        }
      }

      if (rgb == value) {
        x = i;
        y = j;
        const xPercentage = (x / ctx.canvas.width) * 100;
        const yPercentage = (y / ctx.canvas.height) * 100;
        marker.style.top = `${yPercentage}%`;
        marker.style.left = `${xPercentage}%`;

        pixel = ctx.getImageData(x, y, 1, 1)["data"];
        rgb = pixelRGB(pixel);
        emit("update:modelValue", rgb);
      } else if (value) {
        emit("update:modelValue", value);
      } else {
        pixel = ctx.getImageData(x, y, 1, 1)["data"];
        rgb = pixelRGB(pixel);
        emit("update:modelValue", rgb);
      }
    };

    const checkColor = async (value) => {
      const error = await validate(["isColor"], value);
      if (!error) {
        updateHueCanvas(value);
      }
    };

    window.addEventListener("pointerup", (event) => {
      if (!event.target.closest(`#${props.id}`)) {
        showPicker.value = false;
      }
    });

    onMounted(() => {
      initializeHueCanvas();
      initializeColorCanvas();
    });

    return {
      colorCanvas,
      colorMarker,
      checkColor,
      hueCanvas,
      hueMarker,
      showPicker,
      togglePicker,
    };
  },
};
</script>
