<template>
  <div class="relative flex">
    <input
      class="focus:outline-none block w-full cursor-pointer rounded-l-md border py-1 px-2 text-slate-500 shadow-sm"
      :class="
        invalid
          ? 'border-2 border-rose-600 focus:border-rose-500 focus:ring focus:ring-rose-200'
          : 'border border-slate-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200'
      "
      :id="id"
      readonly
      :style="{
        borderColor:
          focuses.calendar && company.primary && company.primary[500]
            ? company.primary[500]
            : null,
        '--tw-ring-color':
          company.primary && company.primary[200] ? company.primary[200] : null,
      }"
      :value="getLocaleDate(locale, modelValue)"
      @click="toggleCalendar()"
      @focus="focuses.calendar = true"
      @focusout="focuses.calendar = false"
    />
    <button
      class="focus:outline-none rounded-r-md px-2 py-1 text-sm font-semibold focus:ring"
      :class="company.primaryText == 'light' ? 'text-white' : 'text-slate-500'"
      :style="{
        backgroundColor: hovers.calendar
          ? company.primary[600]
          : company.primary[500],
        borderColor:
          focuses.calendar && company.primary && company.primary[500]
            ? company.primary[500]
            : null,
        '--tw-ring-color':
          company.primary && company.primary[200] ? company.primary[200] : null,
      }"
      type="button"
      @click="toggleCalendar()"
      @mouseover="hovers.calendar = true"
      @mouseleave="hovers.calendar = false"
      @focus="focuses.calendar = true"
      @focusout="focuses.calendar = false"
    >
      <CalendarIcon class="h-5 w-5" />
    </button>
    <div
      v-if="showCalendar"
      class="absolute top-full left-1/2 z-20 w-full min-w-full -translate-x-1/2 transform rounded-md border border-slate-300 bg-white sm:w-96"
    >
      <div class="grid grid-cols-3 border-b border-slate-300 py-2">
        <div class="flex items-center justify-center gap-2">
          <button
            class="focus:outline-none rounded-md px-4 py-2 text-sm font-semibold focus:ring"
            :class="
              company.primaryText == 'light' ? 'text-white' : 'text-slate-500'
            "
            :style="{
              backgroundColor: hovers.previous
                ? company.primary[600]
                : company.primary[500],
              borderColor:
                focuses.previous && company.primary && company.primary[500]
                  ? company.primary[500]
                  : null,
              '--tw-ring-color':
                company.primary && company.primary[200]
                  ? company.primary[200]
                  : null,
            }"
            type="button"
            @click="previousYear()"
            @mouseover="hovers.previous = true"
            @mouseleave="hovers.previous = false"
            @focus="focuses.previous = true"
            @focusout="focuses.previous = false"
          >
            <ChevronDoubleLeftIcon class="h-5 w-5" />
          </button>
          <button
            class="focus:outline-none rounded-md px-4 py-2 text-sm font-semibold focus:ring"
            :class="
              company.primaryText == 'light' ? 'text-white' : 'text-slate-500'
            "
            :style="{
              backgroundColor: hovers.previous
                ? company.primary[600]
                : company.primary[500],
              borderColor:
                focuses.previous && company.primary && company.primary[500]
                  ? company.primary[500]
                  : null,
              '--tw-ring-color':
                company.primary && company.primary[200]
                  ? company.primary[200]
                  : null,
            }"
            type="button"
            @click="previousMonth()"
            @mouseover="hovers.previous = true"
            @mouseleave="hovers.previous = false"
            @focus="focuses.previous = true"
            @focusout="focuses.previous = false"
          >
            <ChevronLeftIcon class="h-5 w-5" />
          </button>
        </div>
        <div class="flex items-center justify-center">
          <span class="text-lg font-semibold"
            >{{ t(`months.${month}`) }} {{ year }}</span
          >
        </div>
        <div class="flex items-center justify-center gap-2">
          <button
            class="focus:outline-none rounded-md px-4 py-2 text-sm font-semibold focus:ring"
            :class="
              company.primaryText == 'light' ? 'text-white' : 'text-slate-500'
            "
            :style="{
              backgroundColor: hovers.previous
                ? company.primary[600]
                : company.primary[500],
              borderColor:
                focuses.previous && company.primary && company.primary[500]
                  ? company.primary[500]
                  : null,
              '--tw-ring-color':
                company.primary && company.primary[200]
                  ? company.primary[200]
                  : null,
            }"
            type="button"
            @click="nextMonth()"
            @mouseover="hovers.previous = true"
            @mouseleave="hovers.previous = false"
            @focus="focuses.previous = true"
            @focusout="focuses.previous = false"
          >
            <ChevronRightIcon class="h-5 w-5" />
          </button>
          <button
            class="focus:outline-none rounded-md px-4 py-2 text-sm font-semibold focus:ring"
            :class="
              company.primaryText == 'light' ? 'text-white' : 'text-slate-500'
            "
            :style="{
              backgroundColor: hovers.previous
                ? company.primary[600]
                : company.primary[500],
              borderColor:
                focuses.previous && company.primary && company.primary[500]
                  ? company.primary[500]
                  : null,
              '--tw-ring-color':
                company.primary && company.primary[200]
                  ? company.primary[200]
                  : null,
            }"
            type="button"
            @click="nextYear()"
            @mouseover="hovers.previous = true"
            @mouseleave="hovers.previous = false"
            @focus="focuses.previous = true"
            @focusout="focuses.previous = false"
          >
            <ChevronDoubleRightIcon class="h-5 w-5" />
          </button>
        </div>
      </div>
      <div class="m-2 grid grid-cols-7 gap-1">
        <div
          v-for="day in 7"
          :key="day"
          class="text-md flex items-center justify-center font-light"
        >
          {{ t(`days.${day == 7 ? 0 : day - 1 + startDay}`) }}
        </div>
        <div
          v-for="empty in firstWeekDate == 0 ? 6 : firstWeekDate - 1"
          :key="empty"
          class="invisible"
        >
          {{ empty }}
        </div>
        <div
          v-for="day in lastDate"
          :key="day"
          class="flex items-center justify-center"
        >
          <div
            class="text-md aspect-square focus:outline-none flex w-12 cursor-pointer items-center justify-center rounded-full focus:ring"
            :class="
              isCurrentDate(day) || hovers[day]
                ? company.primaryText == 'light'
                  ? 'text-white'
                  : 'text-slate-500'
                : 'text-slate-500'
            "
            :style="{
              backgroundColor: hovers[day]
                ? company.primary[600]
                : isCurrentDate(day)
                ? company.primary[500]
                : '#fff',
              borderColor:
                focuses[day] && company.primary && company.primary[500]
                  ? company.primary[500]
                  : null,
              '--tw-ring-color':
                company.primary && company.primary[200]
                  ? company.primary[200]
                  : null,
            }"
            @click="selectDate(day)"
            @mouseover="hovers[day] = true"
            @mouseleave="hovers[day] = false"
            @focus="focuses[day] = true"
            @focusout="focuses[day] = false"
          >
            {{ day }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CalendarIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/vue/24/outline";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import {
  getISODate,
  getLocaleDate,
  getMonth,
  getYear,
} from "../../utils/dateUtils";

export default {
  components: {
    CalendarIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  props: {
    id: {
      required: false,
      type: String,
    },
    invalid: {
      default: false,
      required: false,
      type: Boolean,
    },
    modelValue: {
      required: true,
      type: String,
    },
    startDay: {
      default: 1,
      required: false,
      type: Number,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { locale, t } = useI18n();
    const store = useStore();

    const company = computed(() => store.state.company.company);

    const focuses = ref([]);
    const hovers = ref([]);

    const month = ref(
      getMonth(props.modelValue ? props.modelValue : new Date())
    );
    const year = ref(getYear(props.modelValue ? props.modelValue : new Date()));

    const firstDate = computed(
      () => new Date(`${year.value}-${month.value + 1}-1`)
    );
    const lastDate = computed(() => {
      const date = new Date(
        `${year.value}-${month.value == 11 ? 1 : month.value + 2}-1`
      );
      date.setDate(0);
      return date.getDate();
    });
    const firstWeekDate = computed(() => firstDate.value.getDay());

    const showCalendar = ref(false);

    const toggleCalendar = () => {
      showCalendar.value = !showCalendar.value;
      hovers.value = [];
    };

    const previousMonth = () => {
      if (month.value > 0) {
        month.value--;
      } else {
        month.value = 11;
        year.value--;
      }
    };

    const nextMonth = () => {
      if (month.value < 11) {
        month.value++;
      } else {
        month.value = 0;
        year.value++;
      }
    };

    const previousYear = () => {
      year.value--;
    };

    const nextYear = () => {
      year.value++;
    };

    const selectDate = (day) => {
      const date = new Date(`${year.value}-${month.value + 1}-${day}`);
      emit("update:modelValue", getISODate(date));
      toggleCalendar();
    };

    const isCurrentDate = (day) => {
      const date = new Date(`${year.value}-${month.value + 1}-${day}`);
      return getISODate(date) == props.modelValue;
    };

    return {
      company,
      emit,
      firstDate,
      firstWeekDate,
      focuses,
      getLocaleDate,
      hovers,
      isCurrentDate,
      lastDate,
      locale,
      month,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear,
      selectDate,
      showCalendar,
      t,
      toggleCalendar,
      year,
    };
  },
};
</script>
